<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-help"
          icon="pi pi-download"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      id="print"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :loading="loading"
      :filters="filters"
      :expandedRows.sync="expandedRows"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه مهام عمل
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->
      <Column
        field="id"
        header="المسلسل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="بحث بالمسلسل"
          />
        </template>
      </Column>

      <Column
        field="date"
        header="التاريخ"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['date']"
            type="date"
            class="p-column-filter"
            placeholder="بحث بالتاريخ"
          />
        </template>
      </Column>

      <Column field="taskDate" header="تاريخ التنفيذ"> </Column>
      <Column field="taskTime" header="وقت التنفيذ">
        <template #body="slotProps">
          {{ durationFormat(slotProps.data.taskTime) }}
        </template>
      </Column>

      <Column
        field="code"
        header="الكود"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['code']"
            class="p-column-filter"
            placeholder="بحث بالكود"
          />
        </template>
      </Column>
      <Column field="usersId.name" header="الموظف" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بالموظف"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template>
      </Column>
      <Column field="taskDateDo" header="بدا المهمة">
        <template #body="slotProps">
          <InputSwitch v-model="slotProps.data.taskDo" disabled />
          <br />
          {{
            slotProps.data.taskDateDo
              ? durationFormatFull(slotProps.data.taskDateDo)
              : ''
          }}
        </template>
      </Column>
      <Column field="taskTimeDone" header="انتهاء">
        <template #body="slotProps">
          <InputSwitch v-model="slotProps.data.taskDone" disabled />
          <br />

          {{
            slotProps.data.taskTimeDone
              ? durationFormatFull(slotProps.data.taskTimeDone)
              : ''
          }}
        </template>
      </Column>

      <Column field="taskTimeDone" header="انجاز المهمة">
        <template #body="slotProps">
          <!-- <InputSwitch v-model="slotProps.data.taskDone" disabled /> -->
          <!-- <br /> -->
          <p
            v-if="slotProps.data.taskDone"
            v-html="
              $durationFormatFullDiff(
                slotProps.data.taskDateDo,
                slotProps.data.taskTimeDone,
              )
            "
          ></p>
        </template>
      </Column>
      <Column field="taskTime" header="مدة الانتهاء من تاريخ التكليف">
        <template #body="slotProps">
          <p
            v-if="slotProps.data.taskDone"
            v-html="
              $durationFormatFullDiff(
                slotProps.data.createdAt,
                slotProps.data.taskTimeDone,
              )
            "
          ></p>
        </template>
      </Column>

      <Column field="addBy.name" header="المكلف بالمهمه" :sortable="true">
        <!-- <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث بالموظف"
          >
            <option :value="null">الكل </option>
            <option
              v-for="item of usersList"
              :key="item.id"
              :value="item.name"
              >{{ item.name }}</option
            >
          </select>
        </template> -->
      </Column>

      <!-- <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('tasksOrder/edit/' + slotProps.data.id)"
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            v-if="$checkRoles('tasksOrderEdit')"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash" v-tooltip="'حذف'"
            v-if="$checkRoles('tasksOrderDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button>
        </template>
      </Column> -->

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            class="p-datatable-customers table table-striped"
            :value="[slotProps.data]"
          >
            <Column field="note" header="المحتوي" />
          </DataTable>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      usersList: [],
      expandedRows: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    durationFormat(value) {
      const duration = moment(new Date(value));

      return duration.format('hh:mm A');
    },
    durationFormatFull(value) {
      const duration = moment(String(value));

      return duration.format('YYYY-MM-DD hh:mm A');
    },
    getData() {
      this.$http.get(`tasksOrder`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
          this.listx = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    updateStatusOne(data) {
      this.$http
        .put(`tasksOrder/${data.id}`, {
          ...data,
          taskDateDo: new Date(),
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    updateStatusTwo(data) {
      this.$http
        .put(`tasksOrder/${data.id}`, {
          ...data,
          taskTimeDone: new Date(),
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`tasksOrder/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`tasksOrder/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.col-form-label {
  text-align: start;
}
</style>
